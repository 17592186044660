i[class^="icon-"]:before, i[class*=" icon-"]:before {
  @include font(icons);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
  "arrow-left": "\f101",
  "arrow-right": "\f102",
  "smile": "\f103",
);

.icon-arrow-left:before {
  content: map-get($icons-map, "arrow-left");
}
.icon-arrow-right:before {
  content: map-get($icons-map, "arrow-right");
}
.icon-smile:before {
  content: map-get($icons-map, "smile");
}
