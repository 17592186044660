header{
  padding: 1rem;
  background: white;
  @include mq(md){
    padding: 1.875rem;
  }
}

header .logo{
  width: calc(100% - 2rem);
  position: absolute;
  z-index: 999999;
  @include mq(md){
    width: 360px;
  }
  img{
    cursor: pointer;
  }
}

#more{
  width: 26px;
  height: 26px;
  position: fixed;
  z-index: 10;
  left: 39vw;
  top: 17vw;
  display: inline-block;
  cursor: pointer;
  &:before{
    display: block;
    position: absolute;
    content: " ";
    height: 2px;
    width: 100%;
    background: black;
    top: 50%;
  }
  &:after{
    display: block;
    position: absolute;
    content: " ";
    width: 2px;
    min-height: 0;
    background: black;
    left: 50%;
    top: calc(50% + 1px);
    transform: translate(-50%, -50%);
  }
  &.closed{
    &:after{
     height: 100%;
    }
  }
  @include mq(md){
    left: 168px;
    top: 90px;
  }
}


