*{
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar{
  display: none;
}

html, body{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body{
display: grid;
grid-template-rows: min-content 1fr;
}

main{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}





// ________ DEFAULT TEXT STYLING ________ //

h3{
  font-size: 1em;
  margin-bottom: 1em;
}
p{
  margin-bottom: 1em;
}

strong{
  font-weight: bold;
}


// ________ SWIPER OVERLAY ________ //

.the_content{
  line-height: 1.3em;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  height: auto;
  text-align: left;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 1.5em 2.5em 0;
  padding-top: 100px;
  transition: transform $transition-duration-default $transition-timing-default;
  transform: translateX(0%);
  background: white;
  z-index: 141;
  &.closed{
    transform: translateX(-100%);
  }
  &> *{
    max-width: 420px;
  }
}

// ________ OVERLAY ________ //

.overlay{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  padding: 130px 1rem 1rem;
  padding-top: 6rem;
  background: white;
  top: 0;
  left: -100%;
  transition: left $transition-duration-default $transition-timing-default;
  @include mq(md){
    padding: 140px 1.875rem 1.875rem 180px;
  }
}

.overlay.in-view{
  left: 0;
  transition: left $transition-duration-default $transition-timing-default;
}

.overlay-scroll-container{
  padding-top: 2.9em;
  height: 100%;
  overflow: scroll;
}

.overlay .grid{
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 420px));
    grid-gap: 5rem;
  }
}

.overlay .grid .column{
  max-width: 420px;
}


