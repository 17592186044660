.swiper-container, .swiper-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.swiper-container {
  padding: 1rem;
  padding-top: 20vw;
  @include mq(md){
    padding-top: 1.7rem;
    padding-bottom: 1.875rem;
    padding-left: 180px;
    padding-right: 180px;
  }
}

.custom-cursor-next {
  cursor: url("data:image/svg+xml,%3Csvg id='e75d32af-9ff8-40b3-8f41-dde900df361b' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 225.23 166.13'%3E%3Cpolygon points='212.06 70.72 142.58 1.24 130.39 13.43 191.25 74.29 1.09 74.29 1.09 91.53 191.25 91.53 130.39 152.39 142.58 164.58 212.06 95.1 224.25 82.91 212.06 70.72' fill='%231d1d1b'/%3E%3C/svg%3E") 32 32, auto;
}

.custom-cursor-prev {
  cursor: url("data:image/svg+xml,%3Csvg id='b31d467d-71ad-445c-8cb8-0d6f42811127' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 225.23 166.13'%3E%3Cpolygon points='13.28 95.1 82.76 164.58 94.95 152.39 34.09 91.53 224.25 91.53 224.25 74.29 34.09 74.29 94.95 13.43 82.76 1.24 13.28 70.72 1.09 82.91 13.28 95.1' fill='%231d1d1b'/%3E%3C/svg%3E") 32 32, auto;
}

.swiper-button {
  position: absolute;
  width: 50%;
  height: calc(100% - 100px);
  bottom: 0;
  z-index: 9;

  &.swiper-button-prev {
    left: 0;
    @extend .custom-cursor-prev;
  }

  &.swiper-button-next {
    right: 0;
    @extend .custom-cursor-next;
  }
}

.swiper-slide {
  background: white;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  picture{
    display: block;
    flex: 1;
    height: 50%;
  }
  img {
    height: 100%;
    object-fit: contain;
    object-position: top center;
    @include mq(md){
      object-position: top left;
      height: 100%;
      object-fit: contain;
    }
  }
}

.text-slide {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    line-height: 0.85em;
  }

  .text-inner {
    @include mq(sm) {
      max-width: 33.3333%;
    }
    text-align: center;
    &.align-left{
      text-align: left !important;
    }
    ul{
      list-style-type: "– ";
      margin-left: 1em;
    }
  }
}

.tech-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mq(sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .entry {
    width: 100%;
    border-top: 1px solid;
    margin-top: -1px;
    padding: 0.5em 0 0.52em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > * + * {
      margin-left: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    &:first-of-type {
      border-top: none;
    }

    @include mq(sm) {
      width: 66.66666%;
      display: grid;
      padding: 0.295em 0 0.3em;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.navigation {
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
  position: relative;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    span {
      width: 1em;
      display: inline-block;
      text-align: right;
    }
  }

  @include mq(sm) {
    .pagination {
      position: static;
      left: unset;
      transform: none;
    }
  }
}



// SLIDESHOW MENU //


.slideshow-menu{
  position: relative;
  left: 0;
  font-size: 1rem;
  line-height: 1.28em;
  font-weight: bold;
  height: 110px;
  padding-left: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
  .project, .image{
    display: flex;
    justify-content: space-between;
  }
  @include mq(md){
    padding-left: 15rem;
    justify-content: flex-start;
    .project, .image{
      display: block;
    }
  }

  i{
    font-size: 1.3em;
    position: relative;
    display:inline-block;
    top: 0.01em;
    vertical-align: middle;
    margin: 0 0.1em;
  }

  .swiper-button-next, .next{
    margin-right: 1em;
  }
  
  .swiper-button-next, .swiper-button-prev{
    cursor: pointer;
  }
}

.project .next{
  order: 2;
  margin-right: 0;
  @include mq(md){
    order: unset;
    margin-right: 1em;
  }
}

.image .next{
  order: 2;
  margin-right: 0 !important;
  @include mq(md){
    order: unset;
    margin-right: 1.25em !important;
  }
}

.image{
  span:first-of-type{
    margin-right: 0.1em;
  };
  span:last-of-type{
    margin-left: 0.15em;
    margin-right: 1.25em;
  };
}

.the_content{
  overflow: scroll;
  padding-top: 2.9em;
  @include mq(md) {
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 420px));
    align-items: start;
    justify-items: start;
    grid-gap: 5rem;
  }

  .fr{
    margin-bottom: 3em;
  }
}